function PageError() {

    return(
        <main className=" pageError d-flex  justify-content-center">
            <img alt="logo error" src="img/error/404hack2.png" className="imgError m-5"></img>
        </main>
    )


}

export default PageError;
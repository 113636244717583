function Home() {

    return(
        <main className=" homeBg d-flex flex-column align-items-center justify-content-between">
            <div className="pres d-flex justify-content-center">
                <p className="mt-3">Bienvenue aux délégations internationales sportives</p>
            </div>
        </main>
    )


}

export default Home;